import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyC53I2YRXecsV_r-J60iD18d60NCZDjv40",

  authDomain: "hsgmagazin.firebaseapp.com",

  projectId: "hsgmagazin",

  storageBucket: "hsgmagazin.appspot.com",

  messagingSenderId: "679524173781",

  appId: "1:679524173781:web:35ff626b36124da9501f5c",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
