import React from "react";

const Katalog = ({ shownImg }) => {
  const counter = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80,
  ];
  const allImages = [];

  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(
    require.context("../img_05.11.22", false, /\.(png|jpe?g|svg)$/)
  );

  counter.map((el) => {
    allImages.push({
      id: el,
      src: images[`${el}.jpg`],
    });
  });

  return (
    <div className="max-h-screen h-screen">
      <div className="max-w-screen flex items-center justify-center h-full">
        {allImages.map((el, index) => {
          if (shownImg == 1 && el.id == 1) {
            return (
              <img
                className="h-3/5"
                key={index}
                src={el.src}
                alte="katalog Seite"
              ></img>
            );
          } else if (shownImg == 79 && el.id == 79) {
            return (
              <img
                className="h-3/5"
                key={index}
                src={allImages[el.id].src}
                alte="katalog Seite"
              ></img>
            );
          } else {
            // const leftSide = allImages[el.id -1];
            const rightSide = allImages[el.id];

            if (shownImg == el.id) {
              return (
                <div className="flex w-full md:h-auto md:w-2/3">
                  <img
                    className="w-1/2"
                    key={index}
                    src={el.src}
                    alte="katalog Seite"
                  ></img>
                  <img
                    className="w-1/2"
                    key={index + 1}
                    src={rightSide.src}
                    alte="katalog Seite"
                  ></img>
                </div>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default Katalog;
