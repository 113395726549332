import { useState } from "react";
import Katalog from "./components/Katalog";
import arrow from "./arrow-right.png";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";

function App() {
  const [image, setImage] = useState(1);

  const anzahlPages = 80;

  const back = () => {
    // Eine Seite zurück

    const currentImg = image;

    // letzte Seite
    if (currentImg == anzahlPages - 1) {
      setImage(currentImg - 1);
      return;
    }

    if (currentImg >= 2) {
      if (currentImg > 2) {
        setImage(currentImg - 2);
        return;
      }
      setImage(currentImg - 1);
      return;
    }

    return;
  };
  const forward = () => {
    // Eine Seite zurück

    const currentImg = image;

    // letzte Seite
    if (currentImg == anzahlPages - 2) {
      setImage(currentImg + 1);
      return;
    }

    if (currentImg <= anzahlPages - 3) {
      if (currentImg == 2 || currentImg > 2) {
        setImage(currentImg + 2);
        return;
      }
      setImage(currentImg + 1);
      return;
    }

    return;
  };

  return (
    <div className="h-screen max-h-screen bg-white flex justify-center flex-col">
      <Katalog shownImg={image} />
      <div className="absolute bottom-6 xl:bottom-1/2 py-2 px-16 flex items-center justify-between w-screen text-white">
        {image == 1 ? (
          <button disabled onClick={() => back()} className="cursor:pointer">
            {/* <img src={arrow} className="w-10 rotate-180 opacity-0"></img> */}
            <BsFillArrowLeftSquareFill className="text-red-800 text-5xl opacity-0" />
          </button>
        ) : (
          // <BsFillArrowRightSquareFill className="cursor:pointer"/>
          <button onClick={() => back()} className="cursor:pointer">
            <BsFillArrowLeftSquareFill className="text-red-800 text-5xl" />
            {/* <img src={arrow} className="w-10 rotate-180 xl:left-0"></img> */}
          </button>
        )}
        {image >= anzahlPages - 1 ? (
          ""
        ) : (
          <button onClick={() => forward()} className="cursor:pointer">
            {/* <img src={arrow} className="w-10"></img> */}
            <BsFillArrowRightSquareFill className="text-red-800 text-5xl" />
          </button>
        )}
      </div>
      {/* <div className="flex w-screen justify-center p-2 text-center text-xs md:absolute md:bottom-4 absolute top-0 md:top-auto">
        <a
          href="https://www.flaticon.com/free-icons/arrow"
          className="text-slate-400"
          title="arrow icons"
        >
          Arrow icons created <br /> by deemakdaksina - Flaticon
        </a>
      </div> */}
    </div>
  );
}

export default App;
